<template>
  <component :is="component" v-bind="props">
    <slot />
  </component>
</template>

<script>
import { NuxtLink } from '#components';

export default {
  components: {
    NuxtLink,
  },
  props: {
    to: {
      type: String,
      default: undefined,
    },

    target: {
      type: String,
      default: undefined,
    },

    isStaticByDefault: {
      type: Boolean,
      default: false,
    },
    staticTag: {
      type: String,
      default: 'div',
    },

    type: {
      type: String,
      default: 'button',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    component() {
      switch (true) {
        case !!this.to:
          return 'nuxt-link';
        case this.isStaticByDefault:
          return this.staticTag;
        default:
          return 'button';
      }
    },
    props() {
      const props = {};

      switch (this.component) {
        case 'nuxt-link':
          props.to = this.to;
          if (this.target) props.target = this.target;
          break;
        case 'button':
          props.type = this.type;

          if (this.isDisabled) {
            props.disabled = 'disabled';
          }
          break;
      }

      return props;
    },
  },
};
</script>
