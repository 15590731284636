export const fileTypes = {
  png: 'image/png',
  gif: 'image/gif',
  bmp: 'image/bmp',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  pdf: 'application/pdf',
  doc: 'application/doc',
  webp: 'image/webp', // такое же значение и для avi
  heic: 'application/octet-stream', // same is .heif
  mp4: 'video/mp4',
};

export const fileTypesReverse = Object.fromEntries(
  Object.entries(fileTypes).map((item) => item.reverse()),
);

export function getFileType(arrayBuffer) {
  let header = '';
  let type;
  const arr = new Uint8Array(arrayBuffer).subarray(0, 4);

  arr.forEach((item) => {
    header += item.toString(16);
  });

  // See docs.
  // https://en.wikipedia.org/wiki/List_of_file_signatures
  // https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
  switch (true) {
    case header === '89504e47':
      type = fileTypes.png;
      break;
    case header.indexOf('424d') > -1:
      type = fileTypes.bmp;
      break;
    case header === '47494638':
      type = fileTypes.gif;
      break;
    case header.indexOf('ffd8ff') > -1:
      type = fileTypes.jpeg;
      break;
    case header === '25504446':
      type = fileTypes.pdf;
      break;
    case header === 'd0cf11e0':
      type = fileTypes.doc;
      break;
    case header === '504b34':
      type = 'msOffice';
      break;
    case header === '00018':
      type = fileTypes.heic;
      break;
    case header === '52494646':
      type = fileTypes.webp;
      break;
    case header === '66747970':
      type = fileTypes.mp4;
      break;
    default:
      type = '';
      break;
  }

  return type;
}

export function getBitesFromKb(kbValue) {
  return kbValue * 1024;
}

export function getKbFromMb(MbValue) {
  return MbValue * 1024;
}

export function getBitesFromMb(MbValue) {
  return MbValue * 1024 * 1024;
}

export function getMbFromKb(KbValue) {
  return Intl.NumberFormat('en-EN', {
    maximumFractionDigits: 2,
  }).format(KbValue / 1024);
}

export function getMbFromBites(byteValue) {
  return Intl.NumberFormat('en-EN', {
    maximumFractionDigits: 3,
  }).format(byteValue / 1048576);
}

export function readFileAsync(file, readAsMethod = 'readAsArrayBuffer') {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader[readAsMethod](file);
  });
}

/**
 * @description Возвращает объект DataTransfer, если передан index то исключается файл с указанным индексом.
 * @param {FileList} files - Список файлов.
 * @param {number} index - Индекс файла для исключения.
 * @returns {FileList} Обновленный список файлов, исключая файл с указанным индексом.
 * @throws {Error} Если files и index не заданы.
 */
export function getDataTransfer(files, index) {
  if (!files) throw new Error('there is a nan object in values array');

  const dt = new DataTransfer();

  if (index >= 0) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (index !== i) dt.items.add(file); // here you exclude the file. thus removing it.
    }

    return dt.files; // updated list
  } else {
    for (let i = 0; i < files.length; i++) {
      dt.items.add(files[i]);
    }

    return dt.files; // updated list
  }
}

export function truncateFileName(fileName, maxLength) {
  if (fileName.length <= maxLength) {
    return fileName;
  } else {
    let midLength = Math.floor((maxLength - 6) / 2); // вычисляем длину середины
    let firstPart = fileName.substring(0, midLength); // вырезаем первую часть
    let lastPart = fileName.substring(fileName.length - 6); // оставляем последние 6 символов (точку и расширение)

    return firstPart + '...' + lastPart;
  }
}

export default () => ({
  fileTypes,
  fileTypesReverse,
  getFileType,
  getKbFromMb,
  getBitesFromKb,
  getBitesFromMb,
  getMbFromBites,
  readFileAsync,
  getDataTransfer,
  getMbFromKb,
  truncateFileName,
});
