import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect404_45global from "/app/middleware/redirect404.global.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect404_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.js"),
  "is-authorized": () => import("/app/middleware/is-authorized.js")
}